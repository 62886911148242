import Link from 'next/link';
import classNames from 'classnames';
import React from 'react';

import styles from './styles/BrandPages.module.scss';

/**
 * Footer Content
 * @param {*} props
 */

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerHolder}>
        <div className={classNames('section', styles.logo)}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/images/logo/sasa-logo.svg"
            alt="ICTLife logo"
            className={styles.ictlifeFullLogo}
          />
          <span className={styles.copyright}>
            © {new Date().getFullYear()}. Ideas Come to Life Limited. All Rights
            Reserved.
          </span>
        </div>
        <div className="section">
          <h4>Resources</h4>
          <div className={styles.links}>
            <Link href="/policy">Privacy Policy</Link>
            <Link href="/terms">Terms of Use</Link>
            <Link href="/about">About Us</Link>
            <Link href="/policy#platform-activity-tracking-and-profiling">
              Cookie Policy
            </Link>
            <Link href="/terms#community-interaction-guidelines">
              Community Interaction Policy
            </Link>
          </div>
        </div>
        <div className="section">
          <h4>Links</h4>
          <div className={styles.links}>
            <a
              href={process.env.NEXT_PUBLIC_MERCHANT_WEB_URL}
              target="_blank"
              rel="noreferrer"
            >
              List Your Business
            </a>
            <a
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/ictlife`}
              target="_blank"
              rel="noreferrer"
            >
              ICTLIFE Merchant Team Account
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
