import React from 'react';

import TopBar from '../dashboard-blueprint/TopBar';
import { BrandLinks, MarketLinks } from '../dashboard-blueprint/TopBar/Links';

import styles from './styles/BrandLayout.module.scss';

const BrandLayout = ({ children, sourcePackage }) => (
  <div className={styles.brandLayoutWrapper}>
    <TopBar
      TopBarLinks={sourcePackage === 'marketplace' ? MarketLinks : BrandLinks}
    />
    <div className={styles.contentSection} id="content-section">
      {children}
    </div>
  </div>
);

export default BrandLayout;
